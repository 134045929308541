import React, { useState } from "react"
import Header from "../components/Header"
import "../styles/app.css"
import Helmet from "react-helmet"
import Favicon from "../imgs/favicon.png"
import { Link } from "gatsby"
import ImgTopSofortCasinos from "../imgs/Top-Sofort-Online-Casinos.jpg"
import ImgCashOuts from "../imgs/sofort-cash-outs-up-to-72-hours.jpg"
import ImgMakingDeposit from "../imgs/making-a-deposit-with-a-sofort.png"
import ImgFAQ from "../imgs/frequently-asked-questions–online-banking.png"
import Feature from "../components/Feature"
import IconMoneySaving from "../imgs/icon-money-saving.png"
import IconOnlineGlobe from "../imgs/icon-online-globe.png"
import IconOnlinePayment from "../imgs/icon-online-payment.png"
import BgImg from "../imgs/sofort-online-payment-provider.jpg"
import ImgKlarna from "../imgs/klarna-bought-payment-rival.png"
import ImgFastPaymentService from "../imgs/fast-payment-service.png"
import ImgEn from "../imgs/en.png"
import ImgDe from "../imgs/de.png"

export default function HomeDe() {
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)

  function handleNavBtnClick() {
    setIsHamburgerClicked(prevState => {
      return !prevState
    })
  }

  const mobileNavBarStyle = {
    left: `${isHamburgerClicked ? "10px" : "10000px"}`,
  }

  return (
    <div className="homepage">
      <Helmet>
        <title>Sofort Casinos – Online Casinos That Accept Sofort 2022</title>
        <meta
          name="title"
          content="Sofort Casinos – Online Casinos That Accept Sofort 2022"
        />
        <meta
          name="description"
          content="Sofort Online Casinos - check out online casinos accepting Sofort payment method in 2022 for both deposits & withdrawals. Learn more about Sofort."
        />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="alternate"
          href="https://www.getsofort.com/de"
          hreflang="de"
        />
        <link rel="alternate" href="https://www.getsofort.com" hreflang="en" />
        <link
          rel="alternate"
          href="https://www.getsofort.com"
          hreflang="x-default"
        />
      </Helmet>
      <Header />
      <div className="navigation">
        <div
          role="presentation"
          className="hamburger-icon"
          onClick={handleNavBtnClick}
          onKeyDown={handleNavBtnClick}
        >
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
        </div>
        <ul style={mobileNavBarStyle}>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/#about-sofort">About Sofort</Link>
          </li>
          <li>
            <Link to="/#online-casinos">Online Casinos</Link>
          </li>
          <li>
            <Link to="/#deposit">Deposit</Link>
          </li>
          <li>
            <Link to="/#cashouts">Cashouts</Link>
          </li>
          <li>
            <Link to="/#faq">FAQ</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
        </ul>
      </div>
      <div className="langs">
        <Link to="/">
          <img src={ImgEn} alt="English" />
        </Link>
        <Link to="/de">
          <img src={ImgDe} alt="Deutsch" />
        </Link>
      </div>
      <div className="home" id="home">
        <div className="home-top">
          <img
            src={BgImg}
            alt="sofort-online-payment-provider"
            className="home-image"
          />
          <h1
            className="centered-in-image"
            style={{ textTransform: "uppercase" }}
          >
            Sofort Online Casinos
          </h1>
        </div>
        <div className="features">
          <Feature
            img={IconMoneySaving}
            alt="Money Saving"
            title="Fast and Easy"
            content="With Sofort Banking, you can now make deposits to your favorite online casino in a matter of seconds."
          />
          <Feature
            img={IconOnlineGlobe}
            alt="Online Global"
            title="No Extra Steps"
            content="There is no need to register with Sofort Banking. All you need is your everyday online banking account and you’re good to go."
          />
          <Feature
            img={IconOnlinePayment}
            alt="Online Payment"
            title="No Data, No Problems"
            content="The security with Sofort Uberweisung is top-notch. Since none of your private data is ever stored on any servers, there is no actual risk of any data breach."
          />
        </div>
        <p>
          Sofort is the cool k'id on the digital block able to disrupt the
          traditional slow-processing online payment industry. With Sofort
          banking you can now make direct bank transfers without the usual
          time-consuming form fillings and nagging delays. Using this innovative
          payment method, your bank transfers will be made in a blink of an eye,
          in a secure and safe environment.
        </p>
        <p>
          Be sure to check the best Sofort casinos all ready to accept the
          future and let you play your favorite casino games in no time. Find
          out more about Sofort payment method and online casinos that accept
          Sofort in 2022 in the following lines.
        </p>
      </div>
      <div className="about-sofort section" id="about-sofort">
        <h2>About Sofort Payment Method</h2>
        <p>
          Initially founded as a branch to a German stock trading company in
          early 2006, Sofort Uberweisung gathered steam in Germany and soon
          became one of Germans’ favorite online payment methods.
        </p>
        <img
          src={ImgTopSofortCasinos}
          alt="Top Sofort Online Casinos"
          className="content-img"
        />
        <p>
          In 2014, Sofort was acquired by Swedish financial service provider
          Klarna Bank AB, thus making a smooth transition from a local German
          means of payment to a popular financial service available throughout
          Europe.
        </p>
        <p>
          As of 2020, Klarna is valued at over 2 billion EUR. Just last year,
          the company helped more than 60 million users connect with some 90,000
          online merchants, from all around the world, including the US.
        </p>
      </div>
      <div className="online-casinos section" id="online-casinos">
        <h2 className="content-title">
          Online Casinos That Accept Sofort In 2022
        </h2>
        <p className="content-text">
          The online casinos couldn’t let this opportunity pass by, so they
          started implementing Sofort as first a deposit and later a withdrawal
          option. And why wouldn’t they? With more and more online buyers using
          Sofort banking as their default payment method, the online casino
          players are now requesting the Sofort Uberweisung implementation to as
          many casino websites as possible.
        </p>
        <img
          src={ImgKlarna}
          alt="Klarna kaufte Zahlungsrivale"
          className="content-img"
        />
        <p className="content-text">
          Luckily for you, most online casinos received the feedback and are now
          in the process of introducing Sofort to its clients, if they haven’t
          done so already. If you don’t believe us, just check our best Sofort
          online casinos.
        </p>
        <p className="content-text">
          Is your favorite casino NOT in our list? It will be soon enough but
          till then, how about trying another operator that is Sofort ready?
        </p>
      </div>
      <div className="deposit section" id="deposit">
        <h2>Deposit With Sofort</h2>
        <p>
          The process of making a deposit using Sofort has never been easier.
        </p>
        <img
          src={ImgMakingDeposit}
          alt="Eine Einzahlung mit Sofort tätigen"
          className="content-img"
        />
        <p>
          Just log in to your favorite online casino that accepts Sofort, click
          Deposit, type down the sum you have in mind, choose Sofort banking as
          your payment option and you will be redirected in no time to a secure
          online environment where you will first have to choose your country
          and your preferred bank. Make sure you have an account at the chosen
          bank, the online banking activated, and, most importantly, you have
          the needed funds in that bank account. After the selection, you will
          be asked to enter your online banking details, similar to the way you
          typically log in to your online banking account.
        </p>
        <p>
          Since Sofort is taking care of all the nagging payment details (How
          much? To whom?), the last step is as straightforward as it can be:
          just hit the Confirm button and voila! In a matter of seconds, you
          will have real money in your casino account and you will be more than
          ready to hit the casino tables.
        </p>
      </div>
      <div className="cashouts section" id="cashouts">
        <h2>Cashouts</h2>
        <p className="emphasized">
          If you thought depositing with Sofort banking is easy as pie, wait
          till you experience the cashouts.
        </p>
        <p>
          Since you have made a deposit using Sofort, all the banking details
          will be stored safely so you can withdraw your winnings even simpler
          than you previously deposited. Once more, just choose Sofort banking
          as your withdrawal option, follow the simple steps, click a few
          buttons, and your winnings are on the way to your bank account. No
          need to type down the IBAN or some other banking details.
        </p>
        <img
          src={ImgCashOuts}
          alt="Sofortige Auszahlungen bis zu 72 Stunden"
          className="content-img"
        />
        <p>
          Worth noting though is that withdrawals aren’t lightning-fast as the
          deposits. You will usually have to wait up to 72 hours (or 3 working
          days), depending on your online casino and bank, to fully take
          advantage of your winnings.
        </p>
      </div>
      <div className="faq section" id="faq">
        <h2>FAQ (Frequently Asked Questions)</h2>
        <p className="emphasized">
          Do you have questions regarding Sofort banking? We will do our very
          best to answer them in the FAQ section below.
        </p>
        <img
          src={ImgFAQ}
          alt="Häufig gestellte Fragen – Online-Banking"
          className="content-img"
        />
        <h3>Why should I use Sofort banking instead of an e-wallet?</h3>
        <p>
          The main advantage of using Sofort, compared to a traditional e-wallet
          is the convenience. With Sofort, you don’t have to create an account
          and spend valuable time getting used to the e-wallet’s interface. All
          you have to do is use the familiar online banking account, click
          Confirm, and just wait several seconds to top-up your online casino
          account.
        </p>
        <h3>What fees will I pay if I use Sofort banking?</h3>
        <p>
          For each transfer, Sofort charges the seller a standard 2% plus 0.25
          EUR for any type of digital goods. Since the seller aka the online
          casino can implement extra fees on top of these standard fees, your
          depositing/withdrawal experience may and probably will vary, depending
          on the casino. Please check your online casino website for more
          details.
        </p>
        <h3>Is Sofort Banking Secure?</h3>
        <p>
          Indeed it is. Sofort uses a confirmation code system that offers
          protection against misuse. Moreover, the sensitive online banking data
          (like PIN) introduced by you is not stored and cannot be accessed by
          any party, Sofort or the online casino alike.
        </p>
        <h3>Can I use Sofort without an online banking account?</h3>
        <p>
          Unfortunately, you can’t. To use Sofort, you need an online-capable
          bank account management service, commonly known today as online
          banking. Check your bank in your area for more information.
        </p>
      </div>
      <div className="summary">
        <h2>Summary</h2>
        <p>
          Sofort banking is a fast online payment service that acts as a
          middleman between you, the customer and your bank. There is no need to
          create an account; all you need is a bank account with online
          management capabilities and a trusted online casino accepting Sofort
          as both a deposit and a withdrawal option.
        </p>
        <img
          src={ImgFastPaymentService}
          alt="Schneller Zahlungsservice"
          className="content-img"
        />
        <p className="emphasized">
          If you don’t know where to start looking for such gaming operators,
          check our Sofort casino recommendations.
        </p>
      </div>
      <div className="footer">
        © Copyright 2022. All Rights Reserved by GetSofort
      </div>
    </div>
  )
}
